import "./loadBear.css";
const LoadBear = () => {
  return (
    <>
      <div className="loader-bear"></div>
    </>
  );
};

export default LoadBear;
