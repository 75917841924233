import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">문의사항 | devlucian7@gmail.com</div>
    </footer>
  );
};

export default Footer;
